import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Helmet } from "react-helmet"

export default () => (
<>
<Helmet title="VUE - Download" defer={false} />
<Layout>
<section className="pt-5 md:pt-5">
     <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-4/5">
          <h1 className="text-4xl lg:text-2xl xl:text-2xl font-bold leading-none">
 			Downloads
 		  </h1>
 		  <p className="text-lg lg:text-xl mt-5 font-light">3.3.0 is available for download.</p>
 		  <p className="text-lg lg:text-xl mt-5 font-light">Latest release: October 8, 2015 / 3.3.0</p>		 
		  <h1 className="text-4xl mt-10 lg:text-2xl xl:text-2xl font-bold leading-none">Windows</h1>
		  <a href="https://github.com/VUE/VUE/releases/download/3.3.0/VUE_Installer.zip"><p className="text-lg lg:text-md mt-6 font-light">Download VUE for Windows</p></a>
		  <p className="text-lg lg:text-sm mt-6 font-light">After downloading, double-click on the vue.exe icon on your desktop and follow the directions to install VUE.</p>
		  <h1 className="text-4xl mt-10 lg:text-2xl xl:text-2xl font-bold leading-none">Mac OS</h1>
		  <a href="https://github.com/VUE/VUE/releases/download/3.3.0/VUE.pkg"><p className="text-lg lg:text-md mt-6 font-light">Download VUE for the Mac OS</p></a>
		  <p className="text-lg lg:text-sm mt-6 font-light">After downloading, double click the VUE.pkg and it will install VUE.app to your applications folder.</p>
		  <h1 className="text-4xl mt-10 lg:text-2xl xl:text-2xl font-bold leading-none">Linux / Generic JAR-only version (no installer included)</h1>
		  <a href="https://github.com/VUE/VUE/releases/download/3.3.0/VUE.jar"><p className="text-lg lg:text-md mt-6 font-light">Download the generic VUE version</p></a>
		  <p className="text-lg lg:text-sm mt-6 font-light">After downloading file, double-click on VUE.jar to launch VUE.</p>
		  <p className="text-lg lg:text-sm mt-6 font-light"><a href="https://vue-forums.uit.tufts.edu/posts/list/848.page">See this FAQ topic</a> and the rest of the forums more information: Creating Ubuntu .desktop file for VUE</p>
		  <p className="text-lg lg:text-sm mt-6 font-light">Note: This is for advanced users only. You won't have the benefits of shell integration, controlled JRE version, and heap optimization.</p>
		  <h1 className="text-4xl mt-10 lg:text-2xl xl:text-2xl font-bold leading-none">RELEASE NOTES</h1>
		  <p className="text-lg lg:text-sm mt-6 font-light">For the latest VUE release notes and troubleshooting tips, see the VUE Forums. </p>
 		</div>
 	</div>
</section>
</Layout>
</>
)